import { CommonService } from '@shared/services/common.service';
import { Component, OnInit, Inject, EventEmitter } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material';
import { DataMessageModel } from '@core/model/dataMessage.model';
import { utiles } from '@env/utiles';
import { isNumber } from 'util';
import { PromiseComponent } from '../../../feature/promise/promise.component';


@Component({
  selector: 'app-modal-calendar',
  templateUrl: './modal-calendar.component.html',
  styleUrls: ['./modal-calendar.component.scss'],

})
export class ModalCalendarComponent implements OnInit {
  dateBegin;
  dateEnd;
  prefijoPagina: string;
  dateModel: any;
  onAccept = new EventEmitter();
  date: any;
  btnChatear: string;
  lblTitulo2: string;
  lblTitulo: string;
  clientSecretSeleccionado: string;
  telefonoWhatsapp: string;
  urlWhatsapp: string;
  mostrarWhatsapp: string;
  version_Nueva: number;
  lblTituloFecha: string;

  constructor(@Inject(MAT_DIALOG_DATA) public dataMessage: any,
              private commonService: CommonService
              ) {
    if (this.dataMessage !== null && this.dataMessage !== undefined) {
      this.prefijoPagina = this.dataMessage.prefijoPagina;
      this.dateBegin = this.dataMessage.dateBegin;
      this.dateEnd = this.dataMessage.dateEnd;
      this.clientSecretSeleccionado = this.dataMessage.clientSecretSeleccionado;
      this.btnChatear = this.dataMessage.btnChatear;
      this.lblTitulo = this.dataMessage.titulo;
      this.lblTitulo2 = this.dataMessage.titulo2;
      this.urlWhatsapp = this.dataMessage.urlWhatsapp;
      this.mostrarWhatsapp = this.dataMessage.mostrarWhatsapp;
      this.version_Nueva = this.dataMessage.version_Nueva;
    }
  }

  ngOnInit() {
  }

  onDateSelected() {
    this.onAccept.emit(this.formatoFecha(this.dateModel));
  }

  validacionFecha(fecha) {
    var mes = '';
    switch (fecha.month) {
      case 1: mes = 'Enero';
          break;
      case 2: mes = 'Febrero';
          break;
      case 3: mes = 'Marzo';
          break;
      case 4: mes = 'Abril';
          break;
      case 5: mes = 'Mayo';
          break;
      case 6: mes = 'Junio';
          break;
      case 7: mes = 'Julio';
          break;
      case 8: mes = 'Agosto';
          break;
      case 9: mes = 'Setiembre';
          break;
      case 10: mes = 'Octubre';
          break;
      case 11: mes = 'Noviembre';
          break;
      case 12: mes = 'Diciembre';
          break;
      default:
          mes = '';
    }
    
    this.lblTituloFecha = mes + ' ' + fecha.year;
  }

  formatoFecha(date): string {

    if (isNumber(date.day)) {
      var dayF = `0${date.day}`.slice(-2);
    }
    if (isNumber(date.month)) {
      var monthF = `0${date.month}`.slice(-2);
    }

    return date ?
      `${isNumber(date.day) ? dayF : ''}/${isNumber(date.month) ? monthF : ''}/${date.year}` :
      '';
  }
}
