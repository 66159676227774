import { Component, OnInit, Inject, EventEmitter } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material';
import { DataMessageModel } from '@core/model/dataMessage.model';
import { utiles } from '@env/utiles';
import { isNumber } from 'util';
import { PromiseComponent } from '../../../feature/promise/promise.component';


@Component({
  selector: 'app-modal-monto',
  templateUrl: './modal-monto.component.html',
  styleUrls: ['./modal-monto.component.scss'],

})
export class ModalMontoComponent implements OnInit {
  montoMinimo;
  prefijoPagina: string;
  onAccept = new EventEmitter();
  urlWhatsapp: string;
  mostrarWhatsapp: string;

  constructor(@Inject(MAT_DIALOG_DATA) public dataMessage: any) {
    if (this.dataMessage !== null && this.dataMessage !== undefined) {
      this.prefijoPagina = this.dataMessage.prefijoPagina;
      this.montoMinimo = this.dataMessage.amountMin;
      this.urlWhatsapp = this.dataMessage.urlWhatsapp;
      this.mostrarWhatsapp = this.dataMessage.mostrarWhatsapp;
    }
  }

  ngOnInit() {
  }

  onTryagain() {
    this.onAccept.emit(this.montoMinimo);
  }
 
}
