import { Injectable } from '@angular/core';
import {
    HttpInterceptor,
    HttpRequest,
    HttpResponse,
    HttpHandler,
    HttpEvent,
    HttpErrorResponse
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { utiles } from '@env/utiles';
import { debug } from 'util';

@Injectable({
    providedIn: 'root'
})
export class HttpinterceptorService implements HttpInterceptor {

    constructor() { }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

        // tslint:disable-next-line:prefer-const
        let token = '';

         var infoAuthorization = utiles.getCacheObject('authToken');

        if (infoAuthorization != undefined)
            token = infoAuthorization.Token;

        if (token) {
            request = request.clone({ headers: request.headers.set('Authorization', 'Bearer ' + token) });
        }

        request = request.clone({ headers: request.headers.set('Accept', 'application/json') });

        return next.handle(request).pipe(
            map((event: HttpEvent<any>) => {
                if (event instanceof HttpResponse) {
                    // console.log('event--->>>', event);
                }
                return event;
            }),

            catchError((error: HttpErrorResponse) => {
                
                return throwError(error);
            }));

    }

}
