import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule, Routes } from '@angular/router';
import { CommonService } from './service/common.service';
import { ClientService } from './service/client.service';
import { ModalGeneralComponent } from './modal/modal-general/modal-general.component';
import { MatIconModule, MatDialogModule } from '@angular/material';
import { ModalCalendarComponent } from './modal/modal-calendar/modal-calendar.component';
import { ModalMontoComponent } from './modal/modal-monto/modal-monto.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import {MatButtonModule} from '@angular/material/button';
import { LoadingModule } from './loading/loading.module';
/** Material Module */

/** Services */


@NgModule({
  imports: [
    /* angular stuff */
    CommonModule,
    BrowserAnimationsModule,
    NgbModule,
    MatButtonModule,
    /* 3rd party components */
    FormsModule,
    ReactiveFormsModule,
    MatIconModule,
    MatDialogModule,
    LoadingModule
    /** Material */

  ],
  entryComponents: [
ModalGeneralComponent,
ModalCalendarComponent,
ModalMontoComponent
  ],
  declarations: [
    ModalCalendarComponent,
    ModalMontoComponent,
  ModalGeneralComponent],
  exports: [
    /* angular stuff */
    CommonModule,
    RouterModule,
    ModalGeneralComponent,
    ModalCalendarComponent,
    ModalMontoComponent,
    MatIconModule,
    MatDialogModule
    /* 3rd party components */
    /* our own custom components */

    /** Material Module */

  ],
  providers: [
    CommonService, ClientService
  ]
})
export class SharedModule { }
