import { Injectable } from '@angular/core';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { environment } from '@env/environment';
import { map } from 'rxjs/operators';

const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) };
@Injectable()
export class ClientService {



  constructor(
    private router: Router,
    public http: HttpClient
  ) { }

  /*------------------------------------------------------------------
    Nombre: _validarCliente.
    Descripción: function encargada de validar la informacion del cliente
    Fecha de creación: 03/01/2020
    Autor: Daniel Pacheco Arrieta
    --------------------------------------------------------------------*/
_validarCliente(model: any) {

  const url = environment.apiURL + 'api/Campana/getClientValidation';
  return this.http.post<any>(url, model, httpOptions)
    .pipe(map(response => {
      return response;
    }));
}

  /*------------------------------------------------------------------
    Nombre: _guardarPromesa.
    Descripción: function encargada de guardar la promesa del cliente
    Fecha de creación: 08/01/2020
    Autor: Daniel Pacheco Arrieta
    --------------------------------------------------------------------*/
    _guardarPromesa(model: any) {

      const url = environment.apiURL + 'api/Campana/saveWebPromise';
      return this.http.post<any>(url, model, httpOptions)
        .pipe(map(response => {
          return response;
        }));
    }

     /*------------------------------------------------------------------
    Nombre: _registroAvancePromesa.
    Descripción: function encargada de guardar el avance de la promesa del cliente
    Fecha de creación: 08/01/2020
    Autor: Daniel Pacheco Arrieta
    --------------------------------------------------------------------*/
    _registroAvancePromesa(model: any) {

      const url = environment.apiURL + 'api/Campana/saveRegisterPromise';
      return this.http.post<any>(url, model, httpOptions)
        .pipe(map(response => {
          return response;
        }));
    }

}
