// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
        apiURL: 'https://apigestionesenlinea.akros.tech/',
  ClientSecret_Beto: '69EDE481-9301-47DD-9F16-E4BDFBD78E63',
  ClientSecret_MMCR: 'C763D5E8-5007-48F5-A717-B4EA77F7E69E',
  ClientSecret_MMSV: '2CA4B8F8-8D0D-4384-8DD6-CA39967FE8F9',
  ClientSecret_IMP: '90A13BA5-8B47-412C-8DE7-30C5A27E6110',
  ClientSecret_MMGT: '90A13BA5-8B47-412C-8DE7-30C5A27E6111',
    ClientSecret_MMMX: 'A98AA17A-72B6-411A-98E5-3DB0C04548B8',

  tipoCuenta: 'Cuenta Local',
  email: 'campanaService@service.com',
  value: 'GrupoGenteSMS',
  codigoErrorGenerico: 'R10',
  idMarcaBeto: 3,
  idMarcaMultimoney: 6,
  idMarcaImpulsat: 11,
  idMarcaMultimoneySV: 10,
  idMarcaMultimoneyGT: 7,
  idMarcaMultimoneyMX: 19
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
