import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';


const routes: Routes = [
  {
    path: 'bienvenida/:id',
    loadChildren: () => import('./feature/welcome/welcome.module').then(m => m.WelcomeComponentModule)
  },
  {
    path: 'promesa',
    loadChildren: () => import('./feature/promise/promise.module').then(m => m.PromiseComponentModule)
  },
  {
    path: 'home',
    loadChildren: () => import('./feature/home/home.module').then(m => m.HomeComponentModule)
  },
  {
    path: 'felicidades',
    loadChildren: () => import('./feature/congratulations/congratulations.module').then(m => m.CongratulationsModule)
  },
  {
    path: 'notificacion',
    loadChildren: () => import('./feature/error-promise/error-promise.module').then(m => m.ErrorPromiseModule)
  },
  {
    path: "verificationemail/:user_id",
    loadChildren: () =>
      import("./feature/verificationemail/verficationemail.module").then(
        (m) => m.VerificationemailComponentModule
      ),
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: true })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
