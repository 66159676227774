export class utiles {
  constructor() { }

  /************************LIMPIEZA DEL CACHE*********************/

  static clearCache() {
    localStorage.removeItem("authToken");
    localStorage.removeItem('infoCliente');
    localStorage.removeItem('brand');
  }
  static clearCacheBrand() {
    localStorage.removeItem('brand');
  }
  /************************LIMPIEZA DEL CACHE*********************/

  /************************CREACION DEL CACHE*********************/

  static createCacheObject(name, data) {
    localStorage.setItem(name, JSON.stringify(data));
  }

  static createCacheBrand(data) {
    localStorage.setItem('brand', data);
  }

  /************************CREACION DEL CACHE*********************/

  /************************OBTENER INFORMACION DEL CACHE*********************/

  static getCacheObject(name) {
    var data = localStorage.getItem(name);
    var dataObject = JSON.parse(data);

    return dataObject;
  }

  static getCacheBrand() {
    var data = localStorage.getItem('brand');

    return data;
  }

  /************************OBTENER INFORMACION DEL CACHE*********************/

}