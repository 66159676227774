import { Injectable } from '@angular/core';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { environment } from '@env/environment';
import { map } from 'rxjs/operators';
import { BehaviorSubject } from 'rxjs';

const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) };
@Injectable()
export class CommonService {

  private loading = new BehaviorSubject(false);
  loadingService = this.loading.asObservable();

  constructor(
    private router: Router,
    public http: HttpClient
  ) { }


  /*------------------------------------------------------------------
Nombre: _obtenerEtiquetas.
Descripción: function encargada de obtener la informacion de las etiquetas sin necesidad de estar logueado
Fecha de creación: 02/01/2020
Autor: Daniel Pacheco Arrieta
--------------------------------------------------------------------*/
  _obtenerEtiquetas(model: any) {
    const url = environment.apiURL + 'api/Common/getLabelConfiguration';
    return this.http.post<any>(url, model, httpOptions)
      .pipe(map(response => {
        return response;
      }));
  }

  /*------------------------------------------------------------------
Nombre: _obtenerEtiquetas.
Descripción: function encargada de obtener la informacion de las etiquetas sin necesidad de estar logueado
Fecha de creación: 02/01/2020
Autor: Daniel Pacheco Arrieta
--------------------------------------------------------------------*/
  _login(model: any) {

    var data = "appKey=" + model.clientSecret + "&accountProvider="
      + model.tipoCuenta + "&grant_type=password&username=" + model.email + "&password=" + model.value;

    const url = environment.apiURL + 'token';
    return this.http.post<any>(url, data, httpOptions)
      .pipe(map(response => {
        return response;
      }));
  }

  _setLoading(item: any) {
    this.loading.next(item);
  }

  /*------------------------------------------------------------------
Nombre: _procesarInformacionCliente.
Descripción: function encargada de obtener la configuracion de la pantalla 
             y de procesar la verificación de email del cliente.
Fecha de creación: 19-05-2020
Autor: Elmer Cruz Suarez
--------------------------------------------------------------------*/
  _procesarVerificacionEmailCliente(model: any) {
    const url =
      environment.apiURL + "api/Common/proccessClientVerificationEmail";
    return this.http.post<any>(url, model, httpOptions).pipe(
      map((response) => {
        return response;
      })
    );
  }
}
