import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material';
import { DataMessageModel } from '@core/model/dataMessage.model';
@Component({
  selector: 'app-modal-general',
  templateUrl: './modal-general.component.html',
  styleUrls: ['./modal-general.component.scss']
})
export class ModalGeneralComponent implements OnInit {
  icon: any;
  labelTitile: string;
  textDescription: any;
  status: any;
  constructor(@Inject(MAT_DIALOG_DATA) public dataMessage: DataMessageModel) {
    if (this.dataMessage !== null && this.dataMessage !== undefined) {

      this.labelTitile = this.dataMessage.datainfo.labelTitile;
      this.icon = this.dataMessage.datainfo.icon;
      this.textDescription = this.dataMessage.datainfo.textDescription;
      this.status = this.dataMessage.datainfo.status;

    } else {

      this.labelTitile = 'Lo sentimos parece que algo anda mal';
      
    }
  }

  ngOnInit() {
  }

}
